import React from 'react';
import Modal from 'react-modal';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import './MediaSourceModal.css';
import useServerConfig from '../../../../../utils/useServerConfig';

const MediaSourceModal = ({ isOpen, onRequestClose, openGallaryModal }) => {
    const serverConfig = useServerConfig();

    const handleSignUp = () => {
        const emailInput = document.querySelector('input[type="email"]');
        const email = emailInput.value;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        if (!emailPattern.test(email)) {
            alert('Please check the email address you entered.');
        } else {
            alert('Your email was received. Thank you for signing up!');
    
            // Trigger the Google Ads conversion event
            window.gtag('event', 'conversion', {
                'send_to': 'AW-16732606695/PUM4CMXYqeAZEOeh3ao-'
            });
        }
    };
    
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Email Form"
            appElement={document.getElementById('root')}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: 'auto',
                    whiteSpace: 'nowrap',
                    border: '1px solid #ccc',
                    background: '#101215',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '10px',
                    outline: 'none',
                    padding: '20px'
                }
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <div>
                    <h2>Sign up for wait list</h2>
                    <p>Be the first to know when we launch!</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', width: '96%' }}>
                    <Tooltip target=".disabled-btn" content="Coming Soon!" position="bottom" />
                    <input 
                        type="email" 
                        placeholder="Enter your email" 
                        style={{
                            width: '100%',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            background: '#101215',
                            color: '#fff',
                            outline: 'none',
                            marginBottom: '10px'
                        }} 
                    />
                    <Button label="Sign up" style={{ width: '100%' }} outlined className="media-button" onClick={handleSignUp}/>
                </div>
            </div>
        </Modal>
    );
};

export default MediaSourceModal;

