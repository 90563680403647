import { useEffect, useState } from "react";

// const texts = ['Never charge.', 'your watch.', 'Perpetua.'];
const texts = ['Self charging', 'smartwatch.', 'Perpetua.'];

const TypingText = ({ setFinishedTyping, setOpacity }) => {
    const [textIndex, setTextIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);
    const [blink, setBlink] = useState(true);
    const [reverse, setReverse] = useState(false);
    // typing effect
    useEffect(() => {
        let isLastChar = charIndex === texts[textIndex].length + 1;
        let notLastText = textIndex !== texts.length - 1;
        let isLastText = textIndex === texts.length - 1;
        let isSelfieText = textIndex === texts.length - 2;
        let indexOfFInSelfie = 'selfie.'.indexOf('f') + 1;

        if (isLastChar && isLastText) {
            setFinishedTyping(true);
            setInterval(() => {
                setOpacity(prevOpacity => prevOpacity + 0.1);
            }, 100);
        }

        if (isLastChar &&
            notLastText &&
            !reverse) {
            setReverse(true);
            return;
        }

        if ((charIndex === 0 || (isSelfieText && charIndex === indexOfFInSelfie)) && reverse) {
            setReverse(false);
            setTextIndex((prev) => prev + 1);
            return;
        }

        const timeout = setTimeout(() => {
            setCharIndex((prev) => prev + (reverse ? -1 : 1));
        }, 75);

        return () => clearTimeout(timeout);
    }, [charIndex, textIndex, reverse, setFinishedTyping, setOpacity]);

    // blinking effect
    useEffect(() => {
        const timeout2 = setTimeout(() => {
            setBlink((prev) => !prev);
        }, 500);

        return () => clearTimeout(timeout2);
    }, [blink]);

    return (<h1>
        {`${texts[textIndex].substring(0, charIndex)}${(blink && textIndex !== texts.length - 1) ? '|' : ' '}`}
    </h1>)
}

const TextCreative = ({ finishedTyping, setFinishedTyping }) => {
    const [opacity, setOpacity] = useState(0);
    const isMobile = window.innerWidth <= 500;
    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
        const typingInterval = setInterval(() => {
            setIsTyping(true);
        }, 1000);

        return () => clearInterval(typingInterval);
    }, []);

    return (
        <div style={{width: '90vw'}}>
            {!finishedTyping && <h1 style={{ opacity: "0" }}>placeholder</h1>}
            {!finishedTyping && isMobile && <h1 style={{ opacity: "0" }}>placeholder</h1>}
            {(finishedTyping) && <h1 style={{ opacity: opacity }}>The world's first motion charged smart watch</h1>}
            {isTyping && <TypingText setFinishedTyping={setFinishedTyping} setOpacity={setOpacity} />}
        </div>);
}

export default TextCreative;
