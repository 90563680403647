import { useState, useEffect } from "react";
import Modal from 'react-modal';
import GallaryUploadWidget from "./GallaryUploadWidget";
import GooglePhotosLoginAndFetch from './third_party_upload/GooglePhotos';
import FacebookPhotosLoginAndFetch from './third_party_upload/Facebook';
import MediaSourceModal from "./media_source_modal/MediaSourceModal";
import ChooseSelfieModal from "./choose_selfie_modal/ChooseSelfieModal";
import { uploadImagesEndpoint } from "../../../../utils/jsUtils";
import CheckNofImagesModal from "./check_nof_images_modal/CheckNofImagesModal";

const UploadButton = ({ sessionId, setUserUploadedImages, setUploadModalsClosed }) => {
    const [gallaryUploadModalIsOpen, setGallaryUploadModalIsOpen] = useState(false);
    const [googleModalIsOpen, setGoogleModalIsOpen] = useState(false);
    const [facebookModalIsOpen, setFacebookModalIsOpen] = useState(false);
    const [mediaSourceModalIsOpen, setMediaSourceModalIsOpen] = useState(false);
    const [chooseSelfieModalIsOpen, setChooseSelfieModalIsOpen] = useState(false);
    const [checkNofImagesModalIsOpen, setCheckNofImagesModalIsOpen] = useState(false);
    const [userUploadedSelfie, setUserUploadedSelfie] = useState('');
    const [userConnectedGooglePhotos, setUserConnectedGooglePhotos] = useState(false);
    const [userConnectedFacebook, setUserConnectedFacebook] = useState(false);
    const [imageUrlsToUpload, setImageUrlsToUpload] = useState([]);
    const [enoughImagesFound, setEnoughImagesFound] = useState(null); // will be set by thrid party modals upon check of number of user images
    const isMobile = window.innerWidth <= 500;



    const openGoogleModal = () => {
        setGoogleModalIsOpen(true);
        setUploadModalsClosed(false);
        window.disableBackgroundChange = true;
    };

    const openFacebookModal = () => {
        setFacebookModalIsOpen(true);
        setUploadModalsClosed(false);
        window.disableBackgroundChange = true;
    };

    const openMediaSourceModal = () => {
        setMediaSourceModalIsOpen(true);
        window.disableBackgroundChange = true;
    }
    const closeMediaSourceModal = () => {
        setMediaSourceModalIsOpen(false);
        window.disableBackgroundChange = false;
        window.incrementBgIndex(); // required to trigger render
    }


    const openChooseSelfieModal = () => {
        setChooseSelfieModalIsOpen(true);
        setMediaSourceModalIsOpen(false);
        window.disableBackgroundChange = true;
    }

    const closeChooseSelfieModal = () => {
        setChooseSelfieModalIsOpen(false);
        window.disableBackgroundChange = false;
        window.incrementBgIndex(); // required to trigger render
    }

    const openCheckNofImagesModal = () => {
        setCheckNofImagesModalIsOpen(true);
        setMediaSourceModalIsOpen(false);
        window.disableBackgroundChange = true;
    }
    const closeCheckNofImagesModal = () => {
        if (userConnectedGooglePhotos && !enoughImagesFound) {
            setUserConnectedGooglePhotos(false);
        }
        if (userConnectedFacebook && !enoughImagesFound) {
            setUserConnectedFacebook(false);
        }
        setCheckNofImagesModalIsOpen(false);
        if (!enoughImagesFound) {
            setMediaSourceModalIsOpen(true);
        }
    }

    useEffect(() => {
        window.uploadModalOpen = openMediaSourceModal
    });

    useEffect(() => {
        if ((userConnectedGooglePhotos | userConnectedFacebook) && userUploadedSelfie === '') {
            if (enoughImagesFound) {
                openChooseSelfieModal();
            } else {
                openCheckNofImagesModal();
            }
        }
        else if (userConnectedFacebook && !enoughImagesFound) {
            openCheckNofImagesModal();
        }
    });

    useEffect(() => {
        if (imageUrlsToUpload.length > 0 && userUploadedSelfie !== '' && (userConnectedGooglePhotos | userConnectedFacebook) && sessionId && enoughImagesFound) {
            uploadImagesEndpoint(sessionId, imageUrlsToUpload, userUploadedSelfie);
            setUserUploadedImages(true);
        }
    }, [imageUrlsToUpload, userUploadedSelfie, userConnectedGooglePhotos, userConnectedFacebook, setUserUploadedImages, sessionId, enoughImagesFound]);


    return (
        <div>

            <MediaSourceModal isOpen={mediaSourceModalIsOpen}
                onRequestClose={closeMediaSourceModal}
                openGoogleModal={openGoogleModal}
                openFacebookModal={openFacebookModal} />
        </div>
    )
}

export default UploadButton;