import { useState, } from 'react';
import UploadButton from './UploadButton';

function UploadSection({ vh, vw, sessionId, userUploadedImages, setUserUploadedImages }) {
    const isMobile = window.innerWidth <= 500;
    const [uploadModalsClosed, setUploadModalsClosed] = useState(true);

    return (
        <div style={{ position: 'relative' }}>
            <div style={{
                position: 'absolute',
                top: isMobile ? '80%' : '50%',
                left: isMobile ? '50%' : '30%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
            }}>
                {/* either whow the upload button  */}
                {!userUploadedImages && <UploadButton sessionId={sessionId} setUserUploadedImages={setUserUploadedImages} setUploadModalsClosed={setUploadModalsClosed} />}
            </div>
        </div>
    );
}

export default UploadSection;


