import React from 'react';
import { useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/homepage/HomePage';
import "./App.css"

function App() {
  const [userUploadedImages, setUserUploadedImages] = useState(false);
  const [finishedTyping, setFinishedTyping] = useState(false);
  const sessionId = '123';

  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<HomePage sessionId={sessionId} userUploadedImages={userUploadedImages} setUserUploadedImages={setUserUploadedImages}
          finishedTyping={finishedTyping} setFinishedTyping={setFinishedTyping} />} />
          <Route path="/conversion-success"/>
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;