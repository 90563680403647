import "./Intro.css"
import { useState, useEffect } from "react";
import TryIt from "./tryit/TryIt";
import GlowingButton from "../../buttons/GlowingButton.js";
import useWindowSize from "../../utils/useWindowSize";
import getResizedImageURl from "../../utils/getResizedImageUrl";

function Intro({ backgroundImage, sessionId, userUploadedImages, setUserUploadedImages }) {
    const [screenWidth, screenHeight] = useWindowSize();
    const isMobile = window.innerWidth <= 500;
    let wideImages = screenHeight < screenWidth;
    var watchImageUrl = getResizedImageURl(`v1729181088/peck_beck_ylmfgc.jpg`, screenWidth, 0);
    var genImageUrl = getResizedImageURl(`v1729181088/gen_beck_gt7dol1.jpg`, screenWidth, 0);
    var backImageUrl = getResizedImageURl(`v1729181088/back41_zgzvsu1.jpg`, screenWidth, 0);
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', background: `url(${backgroundImage})`, alignItems: 'center' }}>
            <div style={{ width: '100%', height: '5vh' }}>
            </div>
            <div className="intro-header" style={{ width: '100%', height: '25vh', lineHeight: '0' }}>
                <h1>Perpetua.</h1>
                <h3>Coming Soon</h3>
            </div>
            
            <br />
            <div className="intro-animation" style={{ width: isMobile ? '95vw' : '40vw' }}>
                <div style={{
                    backgroundColor: '#2C2C2C',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(255, 255, 255, 0.3)',
                    maxWidth: '90%',
                    margin: '20px auto',
                    transform: 'translateY(-10px)',
                    transition: 'transform 0.3s ease-in-out',
                    border: '1px solid rgba(255, 255, 255, 0.1)'
                }}>
                    <h1 style={{fontSize: 'calc(15px + 2vmin)', lineHeight: '1.5', color: '#fff'}}>
                        <img src={watchImageUrl} alt="Watch" style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }} />
                        <span style={{color: '#4CAF50', fontSize: 'calc(25px + 2vmin)'}}> Power your watch with every move</span>
                    </h1>
                    <div style={{ color: '#fff', fontSize: 'calc(12px + 1vmin)', lineHeight: '1.5', marginTop: '20px' }}>                        
                        <p><strong>A battery that lasts a month</strong></p>
                        <p><strong>Self-Charging Technology:</strong> We integrate kinetic generators (piezoelectric) to hanress your bodies natural movments, yielding substanially longer battery life.</p>
                        <p><strong>Expected 2025:</strong> First batch is expected to reach markets at the first half of 2025, as a limited experimental series.</p>
                    </div>
                    <h1 style={{fontSize: 'calc(15px + 2vmin)', lineHeight: '1.5', color: '#fff'}}>
                        <img src={backImageUrl} alt="Watch" style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }} />
                        <span style={{color: '#4CAF50', fontSize: 'calc(25px + 2vmin)'}}> All-in-one smartwatch</span>
                    </h1>
                    <div style={{ color: '#fff', fontSize: 'calc(12px + 1vmin)', lineHeight: '1.5', marginTop: '20px' }}>
                        <p><strong>Titanium alloy body:</strong> Lightweight, durable, and resistant to corrosion. Available in Space Gray, Silver and Rose Gold.</p>
                        <p><strong>OLED display:</strong> Crisp, clear visuals with deep blacks and vibrant colors, 368 x 448 pixels for higher-end models. Extremely efficient power usage.</p>
                        <p><strong>ECG sensors:</strong> Advanced health monitoring with electrocardiogram capabilities to keep track of your heart health.</p>
                    </div>
                    <h1 style={{fontSize: 'calc(15px + 2vmin)', lineHeight: '1.5', color: '#fff'}}>
                        <img src={genImageUrl} alt="Gen" style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }} />
                        <span style={{color: '#4CAF50', fontSize: 'calc(25px + 2vmin)'}}> Technological leap in nano generators</span>
                    </h1>
                    <div style={{ color: '#fff', fontSize: 'calc(12px + 1vmin)', lineHeight: '1.5', marginTop: '20px' }}>
                        <p><strong>Android OS:</strong> Seamless integration with your favorite apps and services, offering a familiar and user-friendly interface.</p>
                        <p><strong>LTE support (Microsim):</strong> Stay connected on the go with cellular capabilities, allowing you to make calls and access the internet without needing your phone.</p>
                        <p><strong>Flexible pricing:</strong> Affordable entry-level options available, with higher-end models offering additional features and premium materials.</p>
                    </div>
                </div>
            </div> 
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '5vh', marginBottom: '10vh'}}>
                    <GlowingButton text="Join waitlist &#x2192;" onClick={() => window.uploadModalOpen()}></GlowingButton>
            </div>
            <TryIt sessionId={sessionId} userUploadedImages={userUploadedImages} setUserUploadedImages={setUserUploadedImages} />
        </div>
    )
}

export default Intro;