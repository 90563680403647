import UploadSection from "./upload/UploadSection";

const TryIt = ({ sessionId, userUploadedImages, setUserUploadedImages }) => {
    const isMobile = window.innerWidth <= 500;
    return (
        <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', backgroundColor: '#101215', width: '100vw', alignItems: 'center' }}>

                <UploadSection vw={isMobile ? 90 : 50} vh={isMobile ? 60 : 48} sessionId={sessionId} userUploadedImages={userUploadedImages} setUserUploadedImages={setUserUploadedImages} />
        </div>
    );
};


export default TryIt;