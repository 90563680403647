import React from 'react';
import { Helmet } from 'react-helmet';
import '../../App.css';
import ChangingBackground from './facade/ChangingBackground';
import { Element } from 'react-scroll';
import { useRef, useEffect, useState } from 'react';
import Intro from './intro/Intro';
// import SigninMethodsWidget from '../signup/SigninMethodsWidget';
import Navbar from './navbar/Navbar';
import Footer from './footer/Footer';

const HomePage = ({ sessionId, userUploadedImages, setUserUploadedImages, finishedTyping, setFinishedTyping }) => {
    const shiningStarsCanvasRef = useRef(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const isMobile = window.innerWidth <= 500;

    // shining stars animation
    useEffect(() => {
        const canvas = shiningStarsCanvasRef.current;
        if (canvas) {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = '#101215';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            const gridWidth = 10;
            const gridHeight = 10;

            for (let i = 0; i < gridWidth; i++) {
                for (let j = 0; j < gridHeight; j++) {
                    const x = (i + Math.random()) * (window.innerWidth / gridWidth);
                    const y = (j + Math.random()) * (window.innerHeight / gridHeight);
                    ctx.fillStyle = '#fff';
                    ctx.shadowColor = '#fff';
                    ctx.shadowBlur = 10;
                    ctx.beginPath();
                    ctx.arc(x, y, isMobile ? 2 : 3, 0, Math.PI * 2);
                    ctx.fill();
                }
            }
            setBackgroundImage(canvas.toDataURL());
        }
    }, [isMobile]);

    return (
        <div className="Homepage">
            <Helmet>
                <title>Perpetua - World's first self charging smart watch</title>
                <meta name="description" content="World's first self charging smartwatch. Prepare for perpetual energy." />
                <meta name="keywords" content="smart watch, self charging watch, self charging smart watch" />
                
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Perpetua - World's first self charging smart watch" />
                <meta property="og:description" content="World's first self charging smartwatch. Prepare for perpetual energy." />
                <meta property="og:image" content="https://perpetuawatch.com/camera.png" />
                <meta property="og:url" content="https://perpetuawatch.com" />
                <meta property="og:type" content="website" />
                
                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Perpetua - World's first self charging smart watch" />
                <meta name="twitter:description" content="World's first self charging smartwatch. Prepare for perpetual energy." />
                <meta name="twitter:image" content="https://perpetuawatch.com/camera.png" />

                {/* Some more meta tags */}
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Perpetua" />
                <meta itemprop="name" content="Perpetua - World's first self charging smart watch" />
                <meta itemprop="description" content="World's first self charging smartwatch. Prepare for perpetual energy." />
                <meta itemprop="image" content="https://perpetuawatch.com/camera.png" />
                <meta name="pinterest-rich-pin" content="true" />

            </Helmet>
            <Navbar />
            <Element name="background" className="element">
                <ChangingBackground finishedTyping={finishedTyping} setFinishedTyping={setFinishedTyping} />
            </Element>
            <Element >
                {/* this is just to render the stars background */}
                <canvas ref={shiningStarsCanvasRef} style={{ display: 'none' }} />
                <Intro backgroundImage={backgroundImage} sessionId={sessionId} userUploadedImages={userUploadedImages} setUserUploadedImages={setUserUploadedImages} />
            </Element>
            <Element>
                <Footer />
            </Element>
            {/* <SigninMethodsWidget sessionId={sessionId} /> */}
        </div>
    );
}

export default HomePage;