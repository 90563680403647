function getResizedImageURl(imageId, width, height) {
    if (height == 0) {
        var url =  `https://res.cloudinary.com/dxgcobmaz/image/upload/w_${width},c_fill,g_face,f_auto,q_auto:best/${imageId}`;
    }
    else {
        var url =  `https://res.cloudinary.com/dxgcobmaz/image/upload/w_${width},h_${height},c_fill,g_face,f_auto,q_auto:best/${imageId}`;
    }
    return url;
}

export default getResizedImageURl;