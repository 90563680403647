import styled from "styled-components";
export const Nav = styled.nav`
	background: transparent;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	/* Fix your navbar by using below two lines of code */
	position: sticky;
	top: 0;
	/* Fix your navbar by using above two lines of code */
	z-index: 10;

`;

export const NavContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 80px;
	z-index: 1;
	width: 100%;
	padding: 0 24px;
`;

export const NavLogo = styled.a`
	justify-self: flex-start;
	align-items: center;
	margin-left: 1vw;
	margin-top: 40px; /* Added to push the logo down */
	font-weight: bold;
	text-decoration: none;
	background-image: url('./logo.png');
	background-size: contain;
	background-repeat: no-repeat;
    height: 80px;
    width: 140px;
    @media (max-width: 768px) {
        width: 120px;
    }
    visibility: visible;
`;


export const NavMenu = styled.div`
	display: flex;
	align-items: center;
	list-style: none;
	text-align: center;
	margin-right: -22px;
    margin-top: 40px

`;

export const NavItem = styled.li`
	height: 80px;
`;

export const NavLinks = styled.a`
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;  // Added this line to center the text
	text-decoration: none;
	margin: 0 0.5rem;  // Added separation from both sides
	height: 50%;
    width: 120px;
	cursor: pointer;
	border: 1px solid #ffffff;
	border-radius: 10px;  // Made the edges round
	text-align: center;  // Centered the text
	&.active {
		color: #ffffff;
	}
`;
