import { useState, useEffect } from "react";
import TextCreative from "./TextCreative.js";
import useWindowSize from "../../utils/useWindowSize";
import getResizedImageURl from "../../utils/getResizedImageUrl";
import GlowingButton from "../../buttons/GlowingButton.js";

function ChangingBackground({ finishedTyping, setFinishedTyping }) {
    const [screenWidth, screenHeight] = useWindowSize();
    const [bgIndex, setBgIndex] = useState(-1);
    const [pageLoaded, setPageLoaded] = useState(false);
    // choose images aspect ratio
    var bgImages = [`v1729181088/first_iteration_oeyxqb.jpg`];
    bgImages = bgImages.map(image => getResizedImageURl(image, screenWidth, screenHeight + 80));

    // preload images
    useEffect(() => {
        // preload images during typing text phase
        bgImages.forEach((image) => {
            const img = new Image();
            img.src = image;
        });
    })

    window.incrementBgIndex = () => {
        // used to control the background change from another page
        setBgIndex((prev) => (prev + 1) % bgImages.length);
    };
    // background change effect
    useEffect(() => {

        let timeoutDuration = bgIndex === -1 ? 5300 : 1300;
        const timeout3 = setTimeout(() => {
            if (window.disableBackgroundChange === true && bgIndex !== -1) {
                // used to control the background change from another page
                return;
            }
            setBgIndex((prev) => (prev + 1) % bgImages.length);
        }, timeoutDuration);

        return () => clearTimeout(timeout3);
    }, [bgIndex, bgImages.length]);

    useEffect(() => {
        setPageLoaded(true);
    }, []);

    return (
        <div className="App-header" id="hero" style={{

            backgroundImage: `url(${bgImages[0]})`,
            backgroundSize: 'cover',
            width: '100%',
            height: `${screenHeight + 80}px`,
            position: 'relative'  // Ensure the container can use absolute positioning for its children
        }}>
            {pageLoaded && <TextCreative finishedTyping={finishedTyping} setFinishedTyping={setFinishedTyping} />}

            {/* <div style={{ position: 'absolute', bottom: '15vh', width: '100%' }}> */}
            <div style={{ bottom: '35vh', width: '100%' }}>
                <div style={{ height: '5vh' }}></div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <GlowingButton text="Join waitlist &#x2192;" onClick={() => window.uploadModalOpen()}></GlowingButton>
                </div>
            </div>
        </div>
    );
}

export default ChangingBackground;